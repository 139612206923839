import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import {
  Banner, Breadcrumbs, DestinationCard, FormSelect,
} from 'components';

import SVGMapMarker from '../../../public/svg/map-marker.svg';

const breadcrumbs = [
  {
    name: PAGES.DESTINATIONS.TITLE,
    absolute_slug: PAGES.DESTINATIONS.PATH,
  },
];

const DEST_POSITIONS = [
  {
    name: 'auckland',
    label: {
      top: 12.5,
      left: 81,
    },
    marker: {
      top: 17,
      left: 66,
    },
  },
  {
    name: 'coromandel',
    label: {
      top: 19.5,
      left: 81,
    },
    marker: {
      top: 18.5,
      left: 72.5,
    },
  },
  {
    name: 'tauranga',
    label: {
      top: 20,
      left: 84,
    },
    marker: {
      top: 18.5,
      left: 68,
    },
  },
  {
    name: 'rotorua',
    label: {
      top: 29,
      left: 88,
    },
    marker: {
      top: 27.5,
      left: 75.7,
    },
  },
  {
    name: 'Murupara',
    label: {
      top: 34,
      left: 88,
    },
    marker: {
      top: 30,
      left: 78,
    },
  },
  {
    name: 'Paihia/Bay of Islands',
    label: {
      top: 6,
      left: 75.5,
    },
    marker: {
      top: 5,
      left: 60,
    },
  },
  {
    name: 'Tongariro National Park',
    label: {
      top: 36.5,
      left: 19.5,
    },
    marker: {
      top: 35.5,
      left: 72,
    },
  },
  {
    name: 'Raglan',
    label: {
      top: 25,
      left: 39.5,
    },
    marker: {
      top: 24.5,
      left: 66,
    },
  },
  {
    name: 'Taupō',
    label: {
      top: 38.5,
      left: 93,
    },
    marker: {
      top: 32,
      left: 74,
    },
  },
  {
    name: 'wellington',
    label: {
      top: 51,
      left: 81.9,
    },
    marker: {
      top: 50,
      left: 66,
    },
  },
  {
    name: 'Matamata (Hobbiton)',
    label: {
      top: 24.7,
      left: 83.5,
    },
    marker: {
      top: 23.7,
      left: 73.5,
    },
  },
  {
    name: 'christchurch',
    label: {
      top: 67.5,
      left: 65,
    },
    marker: {
      top: 66.5,
      left: 49,
    },
  },
  {
    name: 'Kaikōura',
    label: {
      top: 63,
      left: 78,
    },
    marker: {
      top: 58,
      left: 57,
    },
  },
  {
    name: 'franz josef',
    label: {
      top: 66,
      left: 0,
    },
    marker: {
      top: 65,
      left: 31,
    },
  },
  {
    name: 'wanaka',
    label: {
      top: 76.3,
      left: 40,
    },
    marker: {
      top: 75.6,
      left: 23,
    },
  },
  {
    name: 'Picton/Marlborough Sounds',
    label: {
      top: 56,
      left: 70,
    },
    marker: {
      top: 49.8,
      left: 59.2,
    },
  },
  {
    name: 'Punakaiki',
    label: {
      top: 57,
      left: 11,
    },
    marker: {
      top: 55.8,
      left: 39.8,
    },
  },
  {
    name: 'abel tasman national park',
    label: {
      top: 46,
      left: 0,
    },
    marker: {
      top: 45.5,
      left: 49,
    },
  },
  {
    name: 'haast',
    label: {
      top: 68,
      left: 6,
    },
    marker: {
      top: 68.6,
      left: 30,
    },
  },
  {
    name: 'queenstown',
    label: {
      top: 82.5,
      left: 39.5,
    },
    marker: {
      top: 78,
      left: 18,
    },
  },
  {
    name: 'Waitomo',
    label: {
      top: 29,
      left: 39,
    },
    marker: {
      top: 28.5,
      left: 67,
    },
  },
  {
    name: 'Greymouth',
    label: {
      top: 61.3,
      left: 7,
    },
    marker: {
      top: 59.8,
      left: 39.8,
    },
  },
  {
    name: 'Haast Pass',
    label: {
      top: 71.3,
      left: 0,
    },
    marker: {
      top: 70,
      left: 21.8,
    },
  },
  {
    name: 'Milford Sound',
    label: {
      top: 76,
      left: -11,
    },
    marker: {
      top: 75.5,
      left: 13.8,
    },
  },
  {
    name: 'Lake Tekapo',
    label: {
      top: 72,
      left: 50,
    },
    marker: {
      top: 71.5,
      left: 34.8,
    },
  },
];

class DestinationsPage extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super();

    this.onDestinationClick = this.onDestinationClick.bind(this);

    this.DESTINATION_LIST = FORMAT_GRAPH_DATA(props.data.allDestinationPages)
      .map((dest) => {
        const MATCH = DEST_POSITIONS.find(dt => dt.name.toLowerCase() === dest.name.toLowerCase());

        if (MATCH) {
          return {
            ...dest,
            position: MATCH,
          };
        }

        return dest;
      });

    this.state = {
      destinationID: this.DESTINATION_LIST[0].api_id,
    };
  }


  /**
   * On destination click
   * @param id
   */
  onDestinationClick(id) {
    this.setState({ destinationID: id });
  }

  /**
   * Render
   */
  render() {
    const { destinationID } = this.state;
    const destination = this.DESTINATION_LIST.find(item => item.api_id === destinationID);

    return (
      <Layout page="destination">

        <Helmet
          title="New Zealand Trip Planner | Planning a trip to NZ | Haka Tours"
          meta={[
            {
              name: 'description', content: 'Planning a trip to New Zealand? Explore the main New Zealand sightseeing destinations we visit on tour & some key highlights of the North & South Islands.',
            },
            { name: 'og:title', content: 'New Zealand Trip Planner | Planning a trip to NZ | Haka Tours' },
            {
              name: 'og:description', content: 'Planning a trip to New Zealand? Explore the main New Zealand sightseeing destinations we visit on tour & some key highlights of the North & South Islands.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/destinations/` }]}
        />

        <Banner backgroundImage="https://hakatours-staging.imgix.net/000/Destinations.jpeg?fit=crop&auto=format">
          <h1 className="c-heading c-heading--h0">New Zealand Sightseeing Destinations</h1>
        </Banner>

        <div className="l-destinations-map">
          <div className="l-container">
            <Breadcrumbs list={breadcrumbs} />

            <div className="l-destinations-map__header">
              <h2 className="c-heading c-heading--h2">WELCOME TO AOTEAROA NEW ZEALAND!</h2>
              <p>
                We love living in this land of adventure and we’re so excited to show you the best of our country! Planning a trip to New Zealand? We invite you to explore the main New Zealand destinations we stay at and some key highlights.
                Get the lay of the land,let us be your New Zealand trip planners and get ready for the adventure of your lifetime!
              </p>
            </div>

            <form action="" className="l-destinations-map__picker">
              <h3 className="c-heading c-heading--h3">Pick a destination</h3>
              <FormSelect
                id="location"
                name="location"
                onChange={(option) => {
                  this.onDestinationClick(option.location);
                }}
                options={this.DESTINATION_LIST}
              />
            </form>

            <div className="l-destinations-map__grid">

              <div className="l-destinations-map__map">

                <img src="https://static-hakatours.imgix.net/destinations-nz-map-naked-new.png?fit=crop&auto=format" alt="" width="683" />

                {this.DESTINATION_LIST.filter(item => item.position)
                  .map(item => (
                    <React.Fragment key={item.api_id}>
                      <button
                        className={`l-destinations-map__label ${destinationID === item.api_id ? 'is-active' : ''}`}
                        type="button"
                        style={{
                          top: `${item.position ? item.position.label.top : 0}%`,
                          left: `${item.position ? item.position.label.left : 0}%`,
                        }}
                        onClick={() => this.onDestinationClick(item.api_id)}
                      >
                        {item.name}
                      </button>
                      <button
                        className={`l-destinations-map__marker ${destinationID === item.api_id ? 'is-active' : ''}`}
                        type="button"
                        style={{
                          top: `${item.position ? item.position.marker.top : 0}%`,
                          left: `${item.position ? item.position.marker.left : 0}%`,
                        }}
                        onClick={() => this.onDestinationClick(item.api_id)}
                      >
                        <SVGMapMarker />
                      </button>
                    </React.Fragment>
                  ))}
              </div>

              <div className="c-destination-card__wrapper">
                <DestinationCard data={destination} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

DestinationsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allDestinationPages {
      edges {
        node {
          id
          api_id
          name
          short_description
          absolute_slug
          crowdriff_header
          has_discover_more
          crowdriff_code
          first_sub_header_first_picture_id
          first_sub_header_third_picture_id
          first_sub_header_fourth_picture_id
          first_sub_header_fifth_picture_id
        }
      }
    }
  }
`;

export default DestinationsPage;
